<template>
  <div>
    <b-modal
    
      :ref="`my-modal-excel`"
      centered
      ok-only
      ok-title="تم"
      cancel-variant="outline-secondary"
      title-tag="div"
    >
     
      <validation-observer ref="documentexcel">
        <b-card-code>
          <b-form class="excelModel" >
            <b-col cols="12" md="12"   style="height: 250px;">
              <label> اختر الحقول المطلوبة</label>
              <validation-provider #default="{ errors }" rules="required">
                <b-form-group
                  :state="errors.length > 0 ? false : null"
                  label="الحقول المطلوبة"
                  label-for="user-role"
                >
                  <v-select
                    :options="columns"
                    v-model="excelColumns"
                    :reduce="(val) => val.field"
                    :multiple="true"
                    input-id="user-role"
                  />
                </b-form-group>
                <b-form-invalid-feedback
                  :state="errors.length > 0 ? false : null"
                >
                  {{ errors[0] }}
                </b-form-invalid-feedback>
              </validation-provider>
            </b-col>
          </b-form>
        </b-card-code>
      </validation-observer>
      <template #modal-footer>
        <!-- <b-row>
<b-col xl="4"></b-col>
<b-col xl="4"> -->

        <b-button variant="outline-primary" @click="download">
          <feather-icon icon="PlusIcon" class="mr-50" />
          <span class="align-middle"> حفظ </span>
        </b-button>
        <!-- </b-col>

                </b-row> -->
      </template>
      <!-- </b-card-code> -->
    </b-modal>
    <!-- Filters -->
    <services-list-filters
      :gender.sync="gender"
      :family-status.sync="familyStatus"
      :city.sync="city"
      :town.sync="town"
      :village.sync="village"
      :weakness.sync="weakness"
      :plan-filter.sync="planFilter"
      :status-filter.sync="statusFilter"
      :service-filter.sync="serviceFilter"
      :main-service.sync="mainService"
      :gender-options="genderOptions"
      :status-options="statusOptions"
    />
    <b-card no-body>
      <b-card-header class="pb-50" />
      <b-card-body>
        <b-row>
          <b-col cols="4" md="4" class="mb-md-0 mb-2">
            <validation-provider name="search">
              <h6>بحث حسب الاسم او رقم البطاقة</h6>
              <b-form-input
                v-model="search"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                class="w-100"
              />
            </validation-provider>
          </b-col>
        </b-row>
        <b-modal
          :ref="`my-modal`"
          centered
          ok-only
          ok-title="حسنا"
          cancel-variant="outline-secondary"
          title-tag="div"
        >
          <b-table responsive="sm" :items="PhoneData" :fields="fields" />

          <!-- </b-card-code> -->
        </b-modal>
        <hr />
        <b-row>
          {{ userData.permission }}
          <b-col xl="2">
            <label> العمر</label>
          </b-col>

          <b-col cols="" md="3" class="mb-md-0 mb-2">
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>من</h6>
              <b-form-input
                v-model="min_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>

          <b-col cols="" md="3" class="mb-md-0 mb-2">
            <validation-provider
              #default="{ errors }"
              name="titlekn"
              rules="regex:^([0-9]+)$"
            >
              <h6>إلى</h6>
              <b-form-input
                v-model="max_age"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :state="errors.length > 0 ? false : null"
                class="w-100"
              />
              <b-form-invalid-feedback
                :state="errors.length > 0 ? false : null"
              >
                {{ errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-col>
        </b-row>
        <br />
        <b-row>
          <b-col xl="2">
            <label> تاريخ التسجيل</label>
          </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>من</h6>
            <flat-pickr
              v-model="startDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
          <b-col md="3" class="mb-md-0 mb-2">
            <h6>إلى</h6>
            <flat-pickr
              v-model="endDate"
              class="form-control"
              :config="{ maxDate: max }"
            />
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2 row">
        <b-col
          cols="6"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
     
          <label>Show</label>
          <v-select
            v-model="perPage"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="perPageOptions"
            :clearable="false"
            class="per-page-selector d-inline-block mx-50"
          />
          <label>entries</label>
        </b-col>

        <b-col cols="6" md="6" class="">
          <label> {{ totalUsers }} عدد المستفيدين</label>
          <label />
        </b-col>
      </div>
    
      <b-table
        ref="refServiceListTable"
        class="position-relative"
        :items="fetchEXcel"
        responsive
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column::items="fetchEXcel" User primary-key="id" -->

        <!-- Column: Role -->

        <!-- Column: another number
         -->
//display weaknesses
         <template #cell(weaknesses)="data">
          <p 
          v-if="data.item.weaknesses.length != 0" 
          v-for="weak in data.item.weaknesses">
            <span >{{ weak.name }}</span>
          </p>
          
         
          
        </template>


        <template #cell(other_contact_details)="data">
          <b-button
            v-if="Array.isArray(data.item.other_contact_details)"
            variant="outline-primary"
            @click="showModal(data.item.other_contact_details)"
          >
            <!-- <feather-icon icon="CheckSquareIcon" /> -->
            <span class="align-middle ml-50"> ارقام الهواتف الأخرى </span>
          </b-button>
          <span v-else>{{ data.item.other_contact_details.number }}</span>
        </template>

        <!-- Column: Status -->
        <template #cell(service_status)="data">
          <b-badge
            pill
            :variant="
              `light-${resolveUserStatusVariant(data.item.service_status)}`
            "
            class="text-capitalize"
          >
            {{ data.item.service_status }}
          </b-badge>
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <!-- <b-dropdown-item
              :to="{
                name: 'service-committe-report',
                params: { id: data.item.person_id },
              }"
              v-if="data.item.service_status == 'قيد الانتظار'"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تقرير اللجنة</span>
            </b-dropdown-item> -->
            <b-dropdown-item-button
              v-if="data.item.service_status == 'حالة جديدة من قسم التسجيل'"
              @click="requestStatus(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">طلب بدء الحالة </span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="
                role.some((el) => el['name'] === 'information_manager') &&
                  data.item.service_status == 'قيد الانتظار'
              "
              @click="changeStatus(data.item.id, 1)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="
                role.some((el) => el['name'] === 'information_manager') &&
                  data.item.service_status == 'مفتوحة'
              "
              @click="changeStatus(data.item.id, 2)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="
                role.some((el) => el['name'] === 'information_manager') &&
                  data.item.service_status == 'مغلقة'
              "
              @click="changeStatus(data.item.id, 0)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">تغيير الحالة</span>
            </b-dropdown-item-button>
            <b-dropdown-item
              v-if="data.item.service_status == 'مفتوحة'"
              :to="{
                name: 'show-service-report',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">متابعة الحالة</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{
                name: 'show-beneficiary-data',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">عرض الحالة</span>
            </b-dropdown-item>
            <b-dropdown-item
              :to="{ name: 'service-transfer', params: { id: data.item.id } }"
            >
              <feather-icon icon="ArrowLeftIcon" />
              <span class="align-middle ml-50"> تحويل داخلي</span>
            </b-dropdown-item>
            <b-dropdown-item-button
              v-if="
                data.item.service_status == 'قيد الانتظار' &&
                  userData.permissions.some(
                    (el) => el['subject'] === 'start_immediate_case'
                  )
              "
              @click="openStatusImmediate(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">بدء الحالة الفوري </span>
            </b-dropdown-item-button>
            <b-dropdown-item-button
              v-if="
                data.item.service_status == 'قيد الانتظار' &&
                  !userData.permissions.some(
                    (el) => el['subject'] === 'start_immediate_case'
                  )
              "
              @click="openStatus(data.item.id)"
            >
              <feather-icon icon="CheckSquareIcon" />
              <span class="align-middle ml-50">بدء الحالة</span>
            </b-dropdown-item-button>

            <b-dropdown-item
              v-if="data.item.service_status != 'قيد الانتظار'"
              @click="succesStory(data.item.id)"
            >
              <feather-icon icon="StarIcon" />
              <span class="align-middle ml-50">قصة نجاح </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <hr />
      <br />
      <b-row>
        <b-col xl="4" />
        <b-col xl="3">
          <b-button variant="purple" class="shadow" @click="openExcel">
            <vue-excel-xlsx
              ref="childComponent"
              :data="datatest"
              :columns="columns"
              :file-name="filename"
              :file-type="'xlsx'"
              :sheet-name="'sheetname'"
              class="button-excel"
            >
              <span> Export To Excel</span>
            </vue-excel-xlsx>
          </b-button>
        </b-col>
      </b-row>
      <div class="mx-2 mb-2">

        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
        
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="pageNumber"
              :total-rows="totalUsers"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BDropdownItemButton,
  BPagination,
  BCardHeader,
  BCardBody,
  BFormGroup,
  BModal,
  BFormInvalidFeedback,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { ref, onUnmounted } from "@vue/composition-api";
import { avatarText } from "@core/utils/filter";
import { max } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { jsontoexcel } from "vue-table-to-excel";
import ServicesListFilters from "./ServicesListFilters.vue";
import useServicesList from "./useServicesList";
import servicesStoreModule from "./servicesStoreModule";
import store from "@/store";
import FamilyDetailsVue from "../families/family-beneficiaries/Family-Details.vue";

export default {
  setup() {
    const Services_APP_STORE_MODULE_NAME = "app-services";

    // Register module
    if (!store.hasModule(Services_APP_STORE_MODULE_NAME)) {
      store.registerModule(Services_APP_STORE_MODULE_NAME, servicesStoreModule);
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(Services_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(Services_APP_STORE_MODULE_NAME);
      }
    });

    const genderOptions = [
      { label: "ذكر", value: 1 },
      { label: "أنثى", value: 2 },
      { label: "  الكل ", value: 0 },
    ];

    const statusOptions = [
      { label: 'بالأنتظار', value: 0 },
      { label: 'مفتوحة', value: 1 },
      { label: 'مغلقة', value: 2 },
      { label: 'حالة جديدة من قسم التسجيل ', value: 4 },
      { label: 'بانتظار الموافقة', value: 5 },
      { label: 'تم تحويلها', value: 6 },
    ]
    //  const serviceOptions = [
    //   { label: "Pending", value: 0 },
    //   { label: "Opened", value: 1 },
    //   { label: "Closed", value: 2 },

    // ];
    const dataFound = ref(FamilyDetailsVue);
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const maxDate = new Date(today);
    maxDate.setMonth(maxDate.getMonth());

    const {
      fetchBenServices,

      tableColumns,
      fetchEXcel,
      perPage,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,
      ExcelDone,

      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,

      // Extra Filters
      gender,
      familyStatus,
      town,
      village,
      weakness,
      city,
      search,
      min_age,
      max_age,
      startDate,
      fileExcel,
      endDate,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      openStatus,
      openStatusImmediate,
      changeStatus,
      requestStatus,
      pageNumber,
      succesStory,
      datatest,
    } = useServicesList();

    return {
      // Sidebar
      fetchEXcel,
      dataFound,
      datatest,
      ExcelDone,
      fetchBenServices,
      changeStatus,
      requestStatus,
      openStatus,
      openStatusImmediate,
      succesStory,
      tableColumns,
      perPage,
      page,
      totalUsers,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refServiceListTable,
      refetchData,

      // Filter
      search,
      min_age,
      max_age,
      avatarText,
      serviceOptions: [],
      // UI
      resolveUserRoleVariant,
      resolveUserRoleIcon,
      resolveUserStatusVariant,
      pageNumber,
      genderOptions,

      statusOptions,

      // Extra Filters
      fileExcel,
      startDate,
      endDate,
      town,
      village,
      weakness,
      city,
      gender,
      familyStatus,
      planFilter,
      statusFilter,
      serviceFilter,
      mainService,
      max: maxDate,
    };
  },
  components: {
    BModal,
    ServicesListFilters,
    flatPickr,
    BFormGroup,
    BButton,
    max,
    BDropdownItemButton,
    BFormInvalidFeedback,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BRow,
    BCol,
    BFormInput,

    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BCardHeader,
    BCardBody,

    vSelect,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      userData: {},
      role: [],
      excelColumns: [],
      fields: [
        { key: "relation", label: "صلة القرابى" },
        { key: "number", label: "الرقم" },
        { key: "notes", label: "الملاحظات" },
      ],
      PhoneData: [],

      items: [],
      columns: [
        {
          label: "الرقم",
          field: "id",
        },
        {
          label: "الاسم",
          field: "first_name",
        },
        {
          label: "النسبة",
          field: "last_name",
        },
        {
          label: "الجنس",
          field: "gender",
        },
        {
          label: "رقم الهاتف",
          field: "phone_numbers",
        },

        {
          label: "الخدمة",
          field: "service",
        },
        {
          label: "حالة الخدمة",
          field: "service_status",
        },
        {
          label: "تالايخ الميلاد",
          field: "birth_date",
        },

        {
          label: "تاريخ التسجيل",
          field: "registration_date",
        },
        {
          label: "نقاط الضعف",
          field: "weakness",
        },
        {
          label: " المدينة",
          field: "city",
        },
        {
          label: "الحي",
          field: "town",
        },
        {
          label: "طبيعة العائلة",
          field: "family_status",
        },
      ],
      filename: "",
    };
  },
  created() {
    this.userData = JSON.parse(localStorage.getItem("userData"));
    const queryParams = JSON.parse(localStorage.getItem("queryParams"));
    console.log(queryParams)
    this.pageNumber = queryParams.value.page;
    this.gender = queryParams.value.gender;
    this.familyStatus = queryParams.value.familyStatus;
    this.city = queryParams.value.city;
    this.town = queryParams.value.neighborhood;
    this.village = queryParams.value.town;
    this.planFilter = queryParams.value.planFilter;
    this.statusFilter = queryParams.value.service_status;
    this.serviceFilter = queryParams.value.service;
    this.mainService = queryParams.value.main_service;
    this.min_age = queryParams.value.min_age;
    this.max_age = queryParams.value.max_age;
    this.startDate = queryParams.value.maxDate;
    this.endDate = queryParams.value.minDate;
    this.role = this.userData.roles;
    this.search = queryParams.value.search
    this.weakness =  queryParams.value.weakness
    // this.perPage = queryParams.value.page_size
   
  },
  methods: {
    showModal(data) {
      console.log(data);
      this.PhoneData = data;
      this.$refs["my-modal"].show();
    },
    showNumber() {
      this.$refs.childComponent.show();
    },
    openExcel() {
      this.$refs["my-modal-excel"].show();
    },
    async download() {
      return new Promise((resolve, reject) => {
        
      this.$refs["my-modal-excel"].hide();
        this.$refs.documentexcel.validate().then((success) => {
          if (success) {
            this.fileExcel(this.excelColumns).then(() => {
              // console.log(this.datatest);

              this.$refs.childComponent.exportExcel();
            });
          }
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>
<style scoped>
.button-excel {
  border: none;
  padding: 0;
  background: none;
  color: white;
}

div {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
.media {
  color: #7e7a9f;
  font-weight: bold;
  font-family: "Cairo", sans-serif;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
